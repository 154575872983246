@import url("https://use.typekit.net/yzr0six.css");

$larken: "larken", sans-serif;
$dagny: "ff-dagny-web-pro", sans-serif;

$black: #100f0f;
$yellow: #fff7e1;
$pink: #fa07bc;

@mixin text(
  $family: $dagny,
  $size: 1rem,
  $height: 1.55rem,
  $weight: 400,
  $style: normal,
  $color: $yellow,
  // $url: url("https://use.typekit.net/yzr0six.css") format("opentype")
) {
  font-family: $family;
  font-size: $size;
  line-height: $height;
  font-weight: $weight;
  font-style: $style;
  color: $color;
  // src: $url;
}
