@import "../../styles/variables.scss";

.aboutMe {
  gap: 3.45rem;
  overflow-x: hidden;

  .aboutMeContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3.45rem;
    margin: 2rem 5rem 0 5rem;

    .aboutMeContent {
      display: flex;
      flex-direction: column;

      p {
        @include text($size: 1.05rem);
      }

      .aboutButtons {
        display: flex;
        gap: 1rem;
        margin-top: 1.5rem;
      }
    }
  }
  .software {
    margin-top: 4rem;
    .scrollText {
      margin-bottom: 1rem;
      h2 {
        @include text(
          $family: $larken,
          $size: 3.8rem,
          $height: normal,
          $weight: 700
        );
        color: $black;
        -webkit-text-fill-color: $black;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $pink;
        opacity: 0.8;
        z-index: -100;
      }
    }

    p {
      @include text($size: 1.05rem);
      margin: 0 5rem;
      width: 50%;
      z-index: 10;
    }
  }
}

@media (max-width: 900px) {
  .aboutMe {
    .aboutMeContainer {
      gap: 2.5rem;
      grid-template-columns: 60% auto;
    }
    .software {
      .scrollText {
        h2 {
          @include text(
            $family: $larken,
            $size: 2.7rem,
            $height: normal,
            $weight: 700
          );
        }
      }
      p {
        width: 60%;
      }
    }
  }
}

@media (max-width: 750px) {
  .aboutMe {
    .aboutMeContainer {
      grid-template-columns: 70% auto;
    }
    .software {
      .scrollText {
        h2 {
          @include text(
            $family: $larken,
            $size: 2.4rem,
            $height: normal,
            $weight: 700
          );
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .aboutMe {
    .aboutMeContainer {
      margin: 2rem 2rem 0 2rem;
      grid-template-columns: 60% auto;
    }
    .software {
      .scrollText {
        h2 {
          @include text(
            $family: $larken,
            $size: 2.4rem,
            $height: normal,
            $weight: 700
          );
        }
      }
      p {
        margin: 0 2rem;
        width: 70%;
      }
    }
  }
}

@media (max-width: 500px) {
  .aboutMe {
    .aboutMeContainer {
      display: flex;
      flex-direction: column;
      .aboutMeContent {
        p {
          width: 95%;
        }
      }
    }
    .software {
      margin-top: 2.5rem;
      .scrollText {
        h2 {
          @include text(
            $family: $larken,
            $size: 2.4rem,
            $height: normal,
            $weight: 700
          );
        }
      }
      p {
        margin: 0 2rem;
        width: 80%;
      }
    }
  }
}

@media (max-width: 420px) {
  .aboutMe {
    .software {
      .scrollText {
        h2 {
          @include text(
            $family: $larken,
            $size: 2.3rem,
            $height: normal,
            $weight: 700
          );
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .aboutMe {
    .software {
      .scrollText {
        h2 {
          @include text(
            $family: $larken,
            $size: 2.1rem,
            $height: normal,
            $weight: 700
          );
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .aboutMe {
    .software {
      .scrollText {
        h2 {
          @include text(
            $family: $larken,
            $size: 2.2rem,
            $height: normal,
            $weight: 700
          );
        }
      }
      p {
        margin: 0 2rem;
        width: 80%;
      }
    }
  }
}

// @media (max-width: 500px) {
//   .aboutMe {
//     margin: 0 2rem;

//     h1 {
//       font-size: 2.55rem;
//       margin-top: 1.5rem;
//     }
//     .aboutMeContainer {
//       gap: 2.5rem;
//       .aboutMeContent {
//         display: flex;
//         flex-direction: column;
//         gap: 2.5rem;

//         .imageBox {
//           height: 10rem;
//           width: 100%;
//           img {
//             width: 100%;
//             height: 100%;
//           }
//         }
//         .endMeBox {
//           gap: 2rem;
//         }
//       }

//       #secondImg {
//         display: none;
//       }
//     }

//     .svgStopped {
//       align-items: center;
//       display: flex;
//       flex-wrap: wrap;
//       gap: 2rem;
//       justify-content: center;
//       margin: 3.5rem 0 2rem 0;

//       svg {
//         width: 4rem;
//         height: auto;
//       }
//     }
//   }
// }
