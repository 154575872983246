@import "../../../styles/variables.scss";

.button {
  @include text($size: 1.25rem);
  background-color: $black;
  border: 1px solid $yellow;
  padding: 0.65rem 1.25rem;
  border-radius: 1.5rem;
  transition-duration: 0.8s;
  height: fit-content;

  &:hover {
    background-color: $yellow;
    color: $black;
    cursor: pointer;
  }

  .buttonArrow {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.activeButton {
  background-color: $yellow;
  color: $black !important;
}

.iconHeart {
  background-color: $black;
  border: 1.35px solid $yellow;
  border-radius: 100%;
  color: $yellow;
  height: 2.95rem;
  padding: 0.55rem 0.55rem;
  z-index: 500;
  width: 2.95rem;
  transition-duration: 0.7s;

  &:hover {
    color: $black;
    background-color: $yellow;
    cursor: pointer;
  }
}

#heartActive {
  color: $black;
  background-color: $yellow;
}

@media (max-width: 500px) {
  .button {
    @include text($size: 1.05rem);
  }
}
