@import "../../../styles/variables.scss";

.workCardBox {
  width: 100%;
  height: 23rem;
  overflow: hidden;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  //background-color: $yellow;
  position: relative;
  padding: 0;
  transition-duration: 0.75s;

  &:hover {
    box-shadow: 0px 0px 35px 1px rgba(255, 40, 201, 0.3);
    border: 1px solid $pink;
  }

  .iconHeart {
    background-color: rgba($color: $yellow, $alpha: 0.4);
    backdrop-filter: blur(1px);
    border: 1.35px solid $black;
    border-radius: 100%;
    color: $black;
    height: 1.75rem;
    position: absolute;
    padding: 0.25rem 0.25rem;
    top: 0.5rem;
    right: 0.5rem;
    //z-index: 200;
    width: 1.75rem;
    transition-duration: 0.7s;

    &:hover {
      border-color: $pink;
      color: $pink;
      cursor: pointer;
    }
  }

  .heartActive {
    border-color: $pink;
    color: $pink;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  .workTags {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    gap: 0.5rem;
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
  }
}

.imgTop {
  img {
    object-position: left top;
  }
}

@media (max-width: 900px) {
  .workCardBox {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .workCardBox {
    width: 100%;
    height: 10rem;
  }
  .cardHome {
    width: 100%;
    height: 10rem;
  }
}