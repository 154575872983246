@import "../../../styles/variables.scss";

.bottomHero {
  height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;

  .gradient {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;
  }

  //------------

  h1 {
    @include text($size: 6rem, $height: 5.25rem);
    margin-bottom: 2rem;

    @keyframes textShine {
      0% {
        background-position: 0% 50%;
      }

      100% {
        background-position: 100% 50%;
      }
    }

    margin-left: 5rem;

    span {
      @include text(
        $family: $larken,
        $size: 6rem,
        $style: italic,
        $height: normal
      );
      width: 47rem;
      display: block;
      background: linear-gradient(
        to right,
        #7953cd 20%,
        #fe1ebf 30%,
        #ff00b8 70%,
        #764ada 80%
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 500% 150%;
      animation: textShine 10s ease-in-out infinite alternate;
    }
  }

  .scrolling {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 5rem;
    margin: 0 5rem 3.25rem 0;

    .scrollingTxt {
      transition: transform 0.5s;
      transform-origin: 60px 60px;
    }

    &:hover .scrollingTxt {
      transform: rotate(180deg);
    }

    img {
      height: 2rem;
      width: auto;
    }
  }
}

@media (max-width: 970px) {
  .bottomHero {
    align-items: center !important;
    flex-direction: column;
    gap: 2rem;
    height: 85vh;
    justify-content: flex-end;

    h1 {
      margin-left: 0 !important;
      text-align: center;
      span {
        text-align: center;
      }
    }

    .scrolling {
      .scrollingTxt {
        transition: none;
        transform-origin: none;

        &:hover {
          transform: none;
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .bottomHero {
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    height: 75vh;
    justify-content: flex-end;
    margin: 0;

    h1 {
      @include text($size: 3rem !important, $height: normal !important);
      text-align: center !important;
      span {
        @include text(
          $family: $larken,
          $size: 3rem !important,
          $style: italic,
          $height: normal
        );
        text-align: center !important;
        width: 25rem !important;
      }
    }

    .scrolling {
      margin: 0 0 2rem 0 !important;
      .scrollingTxt {
        transition: none;
        transform-origin: none;

        &:hover {
          transform: none;
        }
      }
    }
  }
}
