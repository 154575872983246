@import "../../../styles/variables.scss";

.timeSection {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .underlineContainer {
    display: flex;
    align-items: center;
    width: 100%;

    .square,
    .circle {
      width: 1rem;
      height: 1rem;
      background-color: $yellow;
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: $yellow;
    }

    .circle {
      border-radius: 50%;
    }
  }
}
