@import "../../../styles/variables.scss";

.projectContentContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h2 {
    @include text($family: $larken, $size: 2rem, $height: normal);
    margin-top: 6rem;
  }

  p {
    @include text($size: 1.05rem);
    width: 55%;

    ul {
      padding-left: 1.5rem;
    }
  }

  .subTopics {
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    h3 {
      @include text($family: $larken, $size: 1.65rem, $height: normal);
    }

    ul {
      padding-left: 1.5rem;
    }
  }
}

@media (max-width: 1100px) {
  .projectContentContainer {
    p {
      width: 90%;
    }
  }
}

@media (max-width: 900px) {
  .projectContentContainer {
    p {
      width: fit-content !important;
    }

    .subTopics {
      padding-left: 0;
      ul {
        padding-left: 0;
      }
    }
  }
}

@media (max-width: 600px) {
  .projectContentContainer {
    gap: 1.5rem;
    h2 {
      @include text($family: $larken, $size: 1.8rem, $height: normal);
      margin-top: 5.5rem;
      width: 90%;
    }
    p {
      @include text($size: 0.9rem);

      width: 90%;
    }

    .subTopics {
      padding-left: 0;
      ul {
        padding-left: 0;
      }
    }
  }
}
