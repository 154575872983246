@import "../../styles/variables.scss";

.projectContent {
  margin: 3rem 5rem;

  .seeMoreButton {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }
}

@media (max-width: 500px) {
  .projectContent {
    margin: 1.5rem 2rem;
  }
}
