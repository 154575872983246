@import "../../../styles/variables.scss";

.navSection {
  position: sticky;
  top: 0;
  z-index: 100;

  nav {
    background-color: rgba($color: $black, $alpha: 0.9);
    display: flex;
    justify-content: space-between;
    padding: 2rem 5rem;

    span a {
      @include text($family: $larken, $size: 1.25rem);
      text-decoration: none;
      transition-duration: 0.7s;

      &:hover {
        color: $pink;
      }
    }

    .navPcVersion {
      display: flex;
      gap: 5rem;
      list-style-type: none;

      li a {
        @include text($size: 1.15rem);
        text-decoration: none;
        transition-duration: 0.7s;

        &:hover {
          color: $pink;
        }
      }
    }

    .navBurguer {
      display: none;
    }

    .navBurguerContent {
      display: none;
    }
  }

  .line {
    background-color: $yellow;
    height: 1px;
    width: 100%;
  }
  .isActive {
    @include text(
      $size: 1.15rem !important,
      $style: italic !important,
      $color: $pink !important
    );
  }
}

@media (max-width: 720px) {
  nav {
    padding: 1.5rem 2rem 1.5rem 2rem !important;

    span {
      @include text($height: normal);
      align-items: center;
      display: flex;
    }
    .navPcVersion {
      display: none !important;
    }
    .navBurguer {
      display: block !important;
      z-index: 100;
      transition-duration: 0.3s;
    }

    .open {
      transform: rotate(45deg);
    }

    .navBurguerContent {
      transition-duration: 0.8s;
      align-items: center;
      background-color: $black;
      display: flex !important;
      flex-direction: column;

      height: 100vh;
      justify-content: center;
      left: 0;
      margin: 0;
      position: absolute;
      top: 0;
      width: 100vw;
      z-index: 50;

      ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 3rem;

        li {
          text-align: center;
          a {
            @include text($family: $larken, $size: 2.5rem);
            text-decoration: none;
          }
        }
      }
      .isActive {
        @include text(
          $size: 2.5rem !important,
          $style: italic,
          $color: $pink,
          $family: $larken
        );
      }
    }
  }
}
