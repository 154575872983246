@import "../../../styles/variables.scss";

.aboutSection {
  margin: 0 5rem;
  align-items: center;
  display: grid;
  grid-template-columns: 22% auto 22%;
  text-align: center;
  padding: 15rem 0;

  .flowerDiv {
    align-items: flex-end;
    display: flex;
    height: 100%;
    justify-content: flex-end;

    .flower {
      width: 12rem;
      height: auto;

      path {
        transform-origin: center;
        animation: rotate 40s linear infinite; /* Apply the rotation animation to all paths */
      }

      path:nth-child(2) {
        animation: rotateOpposite 40s linear infinite; /* Apply the opposite rotation to the center path */
      }

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes rotateOpposite {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(-360deg); /* Rotate in the opposite direction */
        }
      }
    }
  }

  .aboutMeText {
    p,
    span {
      @include text($size: 1.3rem, $height: normal);
      mark {
        color: $yellow;
        background-color: #5332d8;
      }

      .underlineHover {
        display: inline-block;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: #5332d8;
          animation: moveUnderline 4s ease-in-out infinite; /* Adjust the duration and animation type as needed */
          transform-origin: bottom left;
        }

        @keyframes moveUnderline {
          0% {
            transform: scaleX(0);
            transform-origin: bottom left;
          }
          25% {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
          50% {
            transform: scaleX(1);
            transform-origin: bottom right;
          }
          75% {
            transform: scaleX(0);
            transform-origin: bottom right;
          }
          100% {
            transform: scaleX(0);
            transform-origin: bottom right;
          }
        }
      }
    }

    .aboutButtons {
      margin-top: 3.5rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;

      .buttonCV {
        margin-right: 1rem;
      }
    }
  }

  .imgContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      width: auto;
      height: 18rem;
    }
  }
}

@media (max-width: 900px) {
  .aboutSection {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 5rem;
    padding: 8rem 0 5rem 0;
    align-items: flex-end;

    .aboutMeText {
      .aboutButtons {
        justify-content: center;
        margin-top: 2rem;
      }
    }

    .imgContainer {
      .meImg {
        display: none;
      }
    }
  }
}

@media (max-width: 600px) {
  .aboutSection {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 2rem;
    padding: 8rem 0 5rem 0;
    align-items: flex-end;

    .aboutMeText {
      p {
        text-align: left;
      }

      .aboutButtons {
        justify-content: flex-start;
        margin-top: 2rem;
      }
    }
    .imgContainer {
      display: none;
    }
  }
}
