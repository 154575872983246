.rotatingElementContainer {
  .svgContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 5rem;
    svg {
      opacity: 0.6;
    }
  }

  .svgStopped {
    display: none;
  }
}

// @media (max-width: 900px) {
//   .rotatingElementContainer {
//     .svgContainer {
//       display: none;
//     }

//     .svgStopped {
//       align-items: center;
//       display: flex;
//       flex-wrap: wrap;
//       gap: 3rem;
//       justify-content: center;
//       margin-top: 5rem;
//     }
//   }
// }

@media (max-width: 900px) {
  .rotatingElementContainer {
    .svgContainer {
      display: none;
    }
  }
}
