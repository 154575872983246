@import "../../../styles/variables.scss";

.workSection {
  margin: 0 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    @include text($family: $larken, $size: 4rem, $height: normal);
    margin-bottom: 1rem;
  }

  .capacityContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 3rem;

    span {
      @include text($size: 1.2rem);
    }

    .diviverCircle {
      width: 0.25rem;
      height: 0.25rem;
      border-radius: 50%;
      background-color: #fb06bb;
      margin: 0 1.15rem;
    }
  }

  .workCardContainer {
    width: 100%;
    position: relative;
    
    .parallaxTest{
      display: flex;
      justify-content: space-between;
      gap: 2.5rem;
    }

    .svgEightStar {
      bottom: -2.5rem;
      left: -2.5rem;
      position: absolute;
      z-index: -1;
    }

    .svgFourStar {
      right: -2.5rem;
      top: -2.5rem;
      position: absolute;
      z-index: -1;
      width: 3rem;
      height: auto;
    }
  }

  button {
    margin-top: 2rem;
  }
}

@media (max-width: 900px) {
  .workSection {
    margin: 0 5rem;

    h2 {
      @include text($family: $larken, $size: 2.65rem, $height: normal);
      text-align: center;
      margin-bottom: 1rem;
    }

    .capacityContainer {
      justify-content: center;

      span {
        @include text($size: 0.95rem);
      }
      .diviverCircle {
        margin: 0 0.4rem;
      }
    }
    .workCardContainer {

      .parallaxTest{
      gap: 1.5rem;
      }

      .svgEightStar {
        width: 4rem;
        height: 4rem;
        bottom: -1.5rem;
        left: -1.5rem;
        position: absolute;
        z-index: -1;
      }

      .svgFourStar {
        width: 2.25rem !important;
        height: auto;
        right: -1.75rem;
        top: -2rem;
        position: absolute;
        z-index: -1;
        width: 3rem;
        height: auto;
      }
    }
  }
}
@media (max-width: 500px) {
  .workSection {
    margin: 0 2rem;

    h2 {
      @include text($family: $larken, $size: 2.55rem, $height: normal);
      text-align: center;
      margin-bottom: 1rem;
    }

    .capacityContainer {
      justify-content: center;

      span {
        @include text($size: 0.9rem);
      }
      .diviverCircle {
        margin: 0 0.4rem;
      }
    }
    .workCardContainer {
      
      .parallaxTest{
      flex-direction: column;
      gap: 1.5rem;
      }

      .svgEightStar {
        width: 4rem;
        height: 4rem;
        bottom: -1.5rem;
        left: -1.5rem;
        position: absolute;
        z-index: -1;
      }

      .svgFourStar {
        width: 2.25rem !important;
        height: auto;
        right: -1.5rem;
        top: -2rem;
        position: absolute;
        z-index: -1;
        width: 3rem;
        height: auto;
      }
    }
    button {
      margin-top: 1.5rem;
    }
  }
}
