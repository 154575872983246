@import "../../../styles/variables.scss";

.bigTicker {
  align-items: center;
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
  span {
    @include text(
      $family: $larken,
      $size: 3rem,
      $weight: 300,
      $color: $yellow,
      $height: normal
    );
    width: fit-content;
  }
  img {
    width: auto;
    height: 3rem !important;
    opacity: 0.6;
  }
}

.smallTicker {
  align-items: center;
  display: flex;
  gap: 1.5rem;
  margin-top: 3rem;
  margin-right: 1.5rem;

  span {
    @include text(
      $family: $larken,
      $size: 1.6rem,
      $weight: 300,
      $color: $yellow,
      $height: normal
    );
    width: fit-content;
  }

  img {
    width: auto;
    // height: 1rem;
    opacity: 0.6;
  }
}

@media (max-width: 900px) {
  .smallTicker {
    margin-top: 5rem;
  }
}

@media (max-width: 500px) {
  .bigTicker {
    span {
      @include text(
        $family: $larken,
        $weight: 300,
        $color: $yellow,
        $height: normal,
        $size: 2rem
      );
    }
  }

  .smallTicker {
    margin-top: 2.5rem;
    span {
      @include text(
        $family: $larken,
        $size: 1.4rem,
        $weight: 300,
        $color: $yellow,
        $height: normal
      );
      width: fit-content;
    }
  }
}

@media (max-width: 350px) {
  .bigTicker {
    span {
      @include text(
        $family: $larken,
        $weight: 300,
        $color: $yellow,
        $height: normal,
        $size: 2rem
      );
    }
  }
}
