.heartContainer {
  width: auto;
  height: 100%;
  top: 1rem;
  right: 55rem;
  position: absolute;
  opacity: 0.5;
  z-index: -100;

  //round
  .heart {
    width: 50rem;
    height: 50rem;
    left: 0;
    top: 0;
    position: absolute;
    border-radius: 100%;
    border: 1.14px #fb06bb solid;
  }

  .heart2 {
    width: 32rem;
    height: 50rem;
    left: 9.5rem;
    position: absolute;
    border-radius: 100%;
    border: 1.14px #fb06bb solid;
    //
    transform-origin: center center;
    transition: transform 0.3s ease-out;
  }

  .heart3 {
    width: 43.5rem;
    height: 50rem;
    left: 3.25rem;
    position: absolute;
    border-radius: 100%;
    border: 1.14px #fb06bb solid;
    //
    transform-origin: center center;
    transition: transform 0.3s ease-out;
  }

  .heart4 {
    width: 27rem;
    height: 50rem;
    left: 11.5rem;
    // top: 270.16px;
    position: absolute;
    border-radius: 100%;
    border: 1.14px #fb06bb solid;
    //
    transform-origin: center center;
    transition: transform 0.3s ease-out;
  }

  .heart5 {
    width: 14rem;
    height: 50rem;
    left: 18rem;
    // top: 234.67px;
    position: absolute;
    transform-origin: 0 0;
    border-radius: 100%;
    border: 1.14px #fb06bb solid;
    //
    transform-origin: center center;
    transition: transform 0.3s ease-out;
  }

  .heart6 {
    width: 5rem;
    height: 50rem;
    left: 22.5rem;
    // top: 204.91px;
    position: absolute;
    transform-origin: 0 0;
    border-radius: 100%;
    border: 1.14px #fb06bb solid;
    //
    transform-origin: center center;
    transition: transform 0.3s ease-out;
  }

  .heart7 {
    width: 16rem;
    height: 50rem;
    left: 17rem;
    top: 0px;
    position: absolute;
    border-radius: 100%;
    border: 1.14px #fb06bb solid;
    //
    transform-origin: center center;
    transition: transform 0.3s ease-out;
  }

  .heart8 {
    width: 4rem;
    height: 50rem;
    left: 23rem;
    position: absolute;
    border-radius: 100%;
    border: 1.14px #fb06bb solid;
    //
    transform-origin: center center;
    transition: transform 0.3s ease-out;
  }
}

@media (max-width: 970px) {
  .heartContainer {
    display: none;
  }
}
