@import "../../../styles/variables.scss";

.tag {
  @include text($size: 0.925rem, $color: $black);
  border-radius: 1.55rem;
  padding: 0.35rem 0.65rem;
  width: fit-content;
  height: fit-content;
}

.ux {
  background-color: #e3d0fb;
}

.web {
  background-color: #fca3de;
}

.front {
  background-color: #ffa6a6;
}

.graphic {
  background-color: #c9f8c2;
}

.branding {
  background-color: #a9e5ff;
}

@media (max-width: 500px) {
  .tag {
    @include text($size: 0.75rem !important);
    color: $black;
  }
}
