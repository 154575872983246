@import "../../styles/variables.scss";

.work {
  .workContent {
    margin: 0 5rem;

    h1 {
      @include text($family: $larken, $size: 4rem, $height: normal);
      margin-top: 3rem;
    }

    .buttonFilter {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-top: 2rem;
    }

    .buttonFilterMobile {
      display: none;
    }
  }
}

@media (max-width: 500px) {
  .workContent {
    margin: 0 2rem !important;
    h1 {
      font-size: 2.55rem !important;
      margin-top: 1.5rem !important;
    }

    .buttonFilter {
      display: none !important;
    }
    .buttonFilterMobile {
      display: flex !important;
      flex-wrap: wrap;
      gap: 1rem;

      .buttonAdd {
        @include text($height: normal, $size: 1.5rem);
        background-color: $black;
        border: none;
      }

      .buttonFilterMobileOpen {
        display: flex !important;
        flex-wrap: wrap;
        gap: 1rem;
      }
    }
  }
}
