@import "./variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: $black;
}

h1,
h2,
h3,
p,
span,
a,
ul,
li,
button,
dt,
dd {
  @include text;
  color: $yellow;
}
