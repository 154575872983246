.imgContainer {
  width: 100%;
  z-index: 2;

  position: relative;
  #img1 {
    width: 15rem;

    img {
      width: 100%;
      height: auto;
    }
  }

  #img2 {
    width: 18rem;
    position: absolute;
    left: 22rem;
    top: -5rem;

    img {
      width: 100%;
      height: auto;
      box-shadow: -1px -1px 20px 1px rgba(119, 16, 78, 0.5);
    }
  }

  #img3 {
    width: 17rem;
    position: absolute;
    top: 13rem;
    left: 4rem;
    img {
      width: 100%;
      height: auto;
      box-shadow: -1px -1px 20px 1px rgba(119, 16, 78, 0.3);
    }
  }

  #img4 {
    width: 17rem;
    position: absolute;
    left: 23rem;
    top: 10rem;
    img {
      width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 1370px) {
  .imgContainer {
    #img4 {
      left: 20rem;
    }
  }
}

@media (max-width: 1050px) {
  .imgContainer {
    #img1 {
      width: 14rem;
    }
    #img3 {
      width: 15rem;
      top: 21rem;
      left: 3rem;
    }

    #img4 {
      width: 15rem;
      left: 11rem;
      top: 21rem;
    }
  }
}

@media (max-width: 900px) {
  .imgContainer {
    #img1 {
      width: 10rem;
    }

    #img3 {
      width: 11rem;
      top: 26rem;
      left: 2rem;
    }

    #img4 {
      width: 11rem;
      left: 6rem;
      top: 18rem;
    }
  }
}

@media (max-width: 780px) {
  .imgContainer {
    #img1 {
      width: 9rem;
    }

    #img3 {
      width: 10rem;
      top: 30rem;
      left: 2rem;
    }

    #img4 {
      width: 10rem;
      left: 6rem;
      top: 19rem;
    }
  }
}

@media (max-width: 500px) {
  .imgContainer {
    display: none;
  }
}
