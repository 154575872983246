@import "../../../styles/variables.scss";

.projHeroSection {
  display: grid;
  grid-template-columns: 55% auto;
  gap: 3rem;
  margin-top: 2rem;

  .projTxtContent {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    h1 {
      @include text($family: $larken, $size: 4rem, $height: normal);
    }

    .tags {
      display: flex;
      gap: 0.5rem;
    }

    p {
      @include text($size: 1.05rem);
    }

    .keyTools {
      border-radius: 0.3125rem;
      border: 1px solid #fb05bb;
      box-shadow: 0 0 10px rgba(251, 5, 187, 0.5);
      width: fit-content;
      padding: 1rem 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .bold {
        @include text($weight: 800);
      }
    }
  }

  .imgContainer {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;

    #imgBox1 {
      grid-column: 1 /6;
      cursor: pointer;
      img {
        max-width: 100%;
        height: auto;
        border-radius: 0.75rem;
      }
    }

    #imgBox2 {
      margin-top: -7.5rem;
      grid-column: 2/7;
      border-radius: 0.75rem;
      box-shadow: -1px -1px 20px 1px rgba(119, 16, 78, 0.3);
      cursor: pointer;

      img {
        max-width: 100%;
        height: auto;
        border-radius: 0.75rem;
      }
    }

    .imgBox {
      position: relative;

      .biggerImg {
        @include text($size: 0.75rem);
        opacity: 0;
        position: absolute;
        padding: 0.5rem 1rem;
        border-radius: 10rem;
        top: 10px;
        left: 10px;
        z-index: 90;
        transition-duration: 0.6s;
      }
      &:hover {
        .biggerImg {
          opacity: 1;
          background-color: rgba(0, 0, 0, 0.8);
          //border: 1px solid $pink;
        }
      }
    }

    .imgFull {
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 100;

      .imgFullContent {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.7);

        figure {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          flex-grow: inherit;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          width: 100vw;
          height: 100vh;
          z-index: 300;

          img {
            border-radius: 0.75rem;
            height: 85vh !important;
            width: auto !important;
            max-width: 100%;
            margin-top: 4rem;
            object-fit: contain;
          }
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  #imgBox1 {
    margin-top: 1rem;
  }
  #imgBox2 {
    box-shadow: none !important;
    img {
      box-shadow: -1px -1px 20px 1px rgba(119, 16, 78, 0.3);
    }
  }
}

@media (max-width: 1000px) {
  .projHeroSection {
    display: flex;
    flex-direction: column;

    .imgContainer {
      #imgBox1 {
        width: 80%;
      }
      #imgBox2 {
        grid-column: 3/8;
        margin-top: -12rem;
        width: 100%;
      }
    }
  }
}

@media (max-width: 600px) {
  .projHeroSection {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    .projTxtContent {
      h1 {
        @include text($family: $larken, $size: 2.55rem, $height: normal);
        margin-bottom: 0.5rem;
      }
      p,
      span {
        @include text($size: 0.9rem);
      }
      .keyTools {
        .bold {
          @include text($size: 0.9rem, $weight: 800);
        }
      }
    }

    .imgContainer {
      #imgBox2 {
        margin-top: -3rem;
      }
    }
  }
}
