@import "../../styles/variables.scss";

.contactContainer {
  margin: 3rem 5rem 0 5rem;

  .titleContact {
    .hPC,
    .hMobile {
      @include text($family: $larken, $size: 4rem, $height: normal);
      //margin-top: 3rem;
    }
    .hMobile {
      display: none;
    }
  }
  .ContactContent {
    display: flex;
    gap: 5rem;
    .imgBox {
      width: 60%;
      height: 30rem;
      overflow: hidden;
      border-radius: 0.25rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 900px) {
  .contactContainer {
    .ContactContent {
      flex-direction: column;
      gap: 5rem;

      .imgBox {
        width: 100%;
        height: 18rem;
      }
    }
  }
}

@media (max-width: 600px) {
  .contactContainer {
    margin: 0 2rem;

    .hPC {
      display: none;
    }

    .hMobile {
      margin-top: 3rem;
      line-height: 5rem !important;
      display: block !important;
    }
    .ContactContent {
      flex-direction: column;
      gap: 2rem;

      .imgBox {
        width: 100%;
        height: 20rem;
      }
    }
  }
}

@media (max-width: 500px) {
  .contactContainer {
    margin: 0 2rem;

    .hMobile {
      font-size: 2.55rem !important;
      line-height: normal !important;
      margin-top: 1.5rem !important;
    }
    .ContactContent {
      flex-direction: column;
      gap: 2rem;

      .imgBox {
        width: 100%;
        height: 20rem;
      }
    }
  }
}
