@import "../../../styles/variables.scss";

.workGridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 4rem;
  gap: 2.5rem;
  z-index: -10;

  a {
    width: 100%;
  }
}

@media (max-width: 900px) {
  .workGridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 4rem;
    gap: 2.5rem;
    z-index: -10;

    a {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .workGridContainer {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    width: 100%;
    gap: 1.5rem;
  }
}
