@import "../../../styles/variables.scss";

.projectBody {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;

  .projectContent {
    display: flex;
    flex-direction: column;
    width: 85%;
  }

  .projectNavigation {
    margin-top: 6rem;
    .navContainer {
      position: sticky;
      top: 8rem;
      border: 1px solid #fb05bb;
      border-radius: 0.3125rem;
      box-shadow: 0 0 10px rgba(251, 5, 187, 0.5);
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1rem 1rem;
      width: fit-content;

      display: flex;
      flex-direction: column;
      a {
        text-decoration: none;
        transition-duration: 0.7s;
        width: fit-content;
      }
      .subTopicNav {
        padding-left: 1.3rem;
      }
      .activeNav {
        text-decoration: underline;
      }
    }
  }

  .projectMobileNav {
    display: none;
  }
}

@media (max-width: 1093px) {
  .projectBody {
    gap: 3rem;
    .projectContent {
      width: 70%;
    }
  }
}

@media (max-width: 900px) {
  .projectBody {
    gap: 3rem;
    .projectContent {
      width: 85%;
    }
    .projectNavigation {
      width: fit-content !important;
    }
  }
}

@media (max-width: 750px) {
  .projectNavigation {
    display: none;
  }
  .projectMobileNav {
    display: flex !important;
    flex-direction: column;
    gap: 1rem;
    position: fixed;
    bottom: 1rem;
    right: 1rem;

    #arrowUp {
      transform: rotate(180deg);
    }

    div {
      background-color: rgba($color: $black, $alpha: 0.5);
      border: 1px solid $pink;
      border-radius: 100%;
      box-shadow: 0 0 5px rgba(251, 5, 187, 0.5);
      padding: 0.7rem 1.1rem;
      svg {
        height: 1.5rem;
      }
    }
  }
}

mark.togetherDoc {
  background-color: #fb05bb;
}
