@import "../../../styles/variables.scss";

.imgContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
  margin-left: 10rem;

  #imgBox1 {
    grid-column: 1 /6;
    border-radius: 0.75rem;
    cursor: pointer;
    width: 28rem;
    height: auto;
    img {
      width: 100%;
      height: 100%;
      border-radius: 0.75rem;
    }
  }

  #imgBox2 {
    margin-top: -8rem;
    grid-column: 2/7;
    border-radius: 0.75rem;
    box-shadow: -1px -1px 20px 1px rgba(119, 16, 78, 0.3);
    cursor: pointer;
    width: 28rem;
    height: auto;

    img {
      width: 100%;
      height: 100%;
      border-radius: 0.75rem;
    }
  }

  .imgBox {
    position: relative;

    .biggerImg {
      @include text($size: 0.75rem);
      opacity: 0;
      position: absolute;
      padding: 0.5rem 1rem;
      border-radius: 10rem;
      top: 10px;
      left: 10px;
      z-index: 90;
      transition-duration: 0.6s;
    }
    &:hover {
      .biggerImg {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.8);
        //border: 1px solid $pink;
      }
    }
  }

  .imgFull {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;

    .imgFullContent {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.7);

      figure {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex-grow: inherit;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        width: 100vw;
        height: 100vh;
        z-index: 300;

        img {
          border-radius: 0.75rem;
          height: 85vh !important;
          width: auto !important;
          max-width: 100%;
          margin-top: 4rem;
          object-fit: contain;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .imgContainer {
    margin-left: 0;

    #imgBox1 {
      width: 25rem;
    }

    #imgBox2 {
      width: 25rem;
      margin-top: -4rem;
    }
  }
}

@media (max-width: 900px) {
  .imgContainer {
    margin-left: 0;

    #imgBox1 {
      width: 22rem;
    }

    #imgBox2 {
      width: 22rem;
      margin-top: -4rem;
    }
  }
}

@media (max-width: 600px) {
  .imgContainer {
    margin-left: 0;

    #imgBox1 {
      width: 15rem;
    }

    #imgBox2 {
      width: 15rem;
      margin-top: -4rem;
    }
  }
}
