@import "../../../styles/variables.scss";

.timeLineSection {
  margin: 5rem 5rem 0 5rem;
  display: flex;
  flex-direction: column;

  .timeContainer {
    display: grid;
    grid-template-columns: 20% auto;

    .timeIdentifier {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      span {
        padding-top: 2rem;
      }

      .line {
        height: 1px;
        width: 100%;
        background-color: $yellow;
      }
    }

    .timeSectionContainer {
      border: 1px solid $yellow;
      border-radius: 0.5rem 0.5rem 0 0;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: auto;
      padding: 1rem;
      row-gap: 1rem;
      width: 100%;

      #firstEd {
        grid-column: 1/4;
        grid-row: 1;
      }

      #secondEd {
        grid-column: 4/6;
        grid-row: 2;
      }

      #firstInter {
        grid-column: 5/6;
        grid-row: 1;
      }

      #firstjeK {
        grid-column: 2/3;
        grid-row: 1;
      }

      #secondjeK {
        grid-column: 3/5;
        grid-row: 2;
      }

      #thirdjeK {
        grid-column: 5/6;
        grid-row: 3;
      }

      #firstShift {
        grid-column: 3/5;
        grid-row: 1;
      }

      #secondShift {
        grid-column: 5/6;
        grid-row: 2;
      }
    }
  }

  .timeDatesContainer {
    display: grid;
    grid-template-columns: 20% auto;

    .timeDates {
      display: flex;
      grid-column: 2;
      justify-content: space-between;
      padding: 0 1rem;
      margin-top: 1rem;
    }
  }

  .cvNote {
    @include text($size: 0.925rem);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
  }
}

.timeMiddle {
  border-radius: 0 0 0 0 !important;
}

.timeBottom {
  border-radius: 0 0 0.5rem 0 !important;
}

@media (max-width: 500px) {
  .timeLineSection {
    display: none;
  }
}
