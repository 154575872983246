@import "../../../styles/variables.scss";
.formContactContainer {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 2rem;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    span {
      @include text($color: $pink, $size: 1.05rem);
    }

    label {
      @include text($size: 1.05rem);
    }

    input {
      @include text($color: $yellow, $size: 1.05rem);
      background-color: $black;
      border: none;
      border-bottom: 1px solid $yellow;
      border-radius: 0;
      height: 2rem;
    }

    textarea {
      @include text($color: $yellow, $size: 1.05rem);
      background-color: $black;
      border: 1px solid $yellow;
      border-radius: 0.5rem;
      resize: none;
    }
  }

  .textareaContainer {
    gap: 1rem;
  }

  .errorContainer {
    .errorMessage {
      @include text($color: $yellow, $size: 1.05rem);
      border: 1px solid $pink;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      width: fit-content;
    }
  }

  button {
    @include text($size: 1.25rem);
    background-color: $black;
    border: 1px solid $yellow;
    padding: 0.65rem 1.25rem;
    border-radius: 1.5rem;
    transition-duration: 0.8s;
    height: fit-content;

    &:hover {
      background-color: $yellow;
      color: $black;
      cursor: pointer;
    }
  }
}
.contactSuccess {
  @include text($size: 1.25rem, $height: normal);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (max-width: 500px) {
  .formContactContainer {
    gap: 1rem;
  }

  .contactSuccess {
    @include text($size: 1.05rem, $height: normal);

    margin-top: 1rem;
  }
}
